export const Layout = {
  content: {
    props: {
      ignoreChildExtend: true,
      position: 'absolute',
      inset: '3.75dvw 3.75dvw 3.75dvw 3.75dvw',
      overflow: 'hidden'
    }
  }
}
