
export const archive = {
  extend: 'Page',

  props: {
    '--row-gap': '1em'
  },

  content: {
    extend: 'Flex',

    props: {
      flow: 'column',
      gap: '--row-gap'
    },

    NavLine: {
      extend: 'Flex',
      props: {
        theme: 'document',
        position: 'sticky',
        top: '0',
        align: 'start',
        flexFlow: 'row wrap',
        zIndex: 3,
        ignoreChildExtend: true,
        '@portrait': {
          fontSize: 'Z'
        }
      },

      childExtend: {
        extend: 'NavLink',
        props: (el, s) => ({
          padding: 'X 1ch',
          cursor: 'pointer',
          isActive: s.activeCategory === el.props.text,
          '.isActive': {
            textDecoration: 'underline'
          },
          ':hover': {
            textDecoration: 'underline'
          },
          onClick: (ev, el, s) => {
            const isCurrent = el.props.text === 'All'
            s.update({ activeCategory: isCurrent || el.props.isActive ? false : el.props.text })
          }
        })
      },

      $propsCollection: [
        { text: 'All' },
        { text: 'Fashion' },
        { text: 'Commercial' },
        { text: 'Corporate' },
        { text: 'Architecture' },
        { text: 'Retouch' }
      ]
    },

    Rows: {
      extend: 'Flex',
      props: {
        flow: 'column',
        gap: '--row-gap'
      },

      childExtend: 'ArchiveLine',
      $stateCollection: ({ state }) => {
        return state.activeCategory ? (state.archive?.data || state.archive).filter(v => v.category === state.activeCategory) : (state.archive?.data || state.archive)
      }
    }
  },

  ImgPreview: {}
}
