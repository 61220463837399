export const Page = {
  props: {
    content: {
      theme: 'document',
      animation: 'fadeInUp',
      animationDuration: 'F',
      position: 'absolute',
      inset: 'calc(var(--layout-outset) + var(--content-outset))',
      transformOrigin: '0 50%',
      overflow: 'hidden auto',
      zIndex: 3,

      '@wideportrait': {
        // inset: 'calc(var(--layout-outset) + 11.5dvw)'
      },

      style: {
        scrollbarColor: 'transparent transparent',
        scrollbarWidth: 'thin'
      }
    }
  }
}
