export const main = {
  extend: 'Page',

  props: {
    content: {
      theme: 'none',
      class: 'canvas'
    }
  },

  content: {}
  // FloatingImg: {}
}
