export const Logo = {
  extend: [
    'Link'
  ],
  props: {
    href: '/'
  },
  Icon: {
    name: 'logo',
    fontSize: 'I'
  }
}
