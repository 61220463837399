import IMGS from './snippets/imgs'
const values = Object.values(IMGS)

export default {
  current: false,
  total: 12
}

export const sta = {
  current: false,
  total: 12,
  archive: [{
    title: ['Tiko Paksa', 'Look Book', 'Fall-Winter 2024'],
    category: 'Material',
    credits: [
      'Production: Rosi Studios',
      'Photo: Aka Prodiashvili',
      'Cast: Streets Casting',
      'Makeup & Hair: Mari Bagramiani',
      'Assistant: Khatuna Zoidze'
    ],
    data: values.slice(0, 10).map(src => ({ src }))
  }, {
    title: ['Materiel', 'Look Book', 'Fall-Winter 2023'],
    category: 'Retouch',
    credits: [
      'Production: Rosi Studios',
      'Photo: Aka Prodiashvili',
      'Cast: Streets Casting',
      'Makeup & Hair: Mari Bagramiani',
      'Assistant: Khatuna Zoidze'
    ],
    data: values.slice(10, 20).map(src => ({ src }))
  }, {
    title: ['George Keburia', 'Look Book', 'Campaign 2024'],
    category: 'Corporate',
    credits: [
      'Production: Rosi Studios',
      'Photo: Aka Prodiashvili',
      'Cast: Streets Casting',
      'Makeup & Hair: Mari Bagramiani',
      'Assistant: Khatuna Zoidze'
    ],
    data: values.slice(20, 30).map(src => ({ src }))
  }, {
    title: ['Tiko Paksa', 'Look Book', 'Fall-Winter 2024'],
    category: 'Fashion',
    credits: [
      'Production: Rosi Studios',
      'Photo: Aka Prodiashvili',
      'Cast: Streets Casting',
      'Makeup & Hair: Mari Bagramiani',
      'Assistant: Khatuna Zoidze'
    ],
    data: values.slice(0, 10).map(src => ({ src }))
  }, {
    title: ['Materiel ', 'Look Book', 'Fall-Winter 2023'],
    category: 'Fashion',
    credits: [
      'Production: Rosi Studios',
      'Photo: Aka Prodiashvili',
      'Cast: Streets Casting',
      'Makeup & Hair: Mari Bagramiani',
      'Assistant: Khatuna Zoidze'
    ],
    data: values.slice(10, 20).map(src => ({ src }))
  }, {
    title: ['George Keburia', 'Look Book', 'Campaign 2024'],
    credits: [
      'Production: Rosi Studios',
      'Photo: Aka Prodiashvili',
      'Cast: Streets Casting',
      'Makeup & Hair: Mari Bagramiani',
      'Assistant: Khatuna Zoidze'
    ],
    data: values.slice(20, 30).map(src => ({ src }))
  }],
  services: ['Fashion', 'Commercial', 'Corporate', 'Architecture', 'Retouch'].map((title, i) => ({
    title,
    description: 'Fashion photography is a genre of photography that portrays clothing and other fashion items. This sometimes includes haute couture garments. It typically consists of a fashion pho-tographer taking pictures of a dressed model in a photographic studio or an outside setting.',
    poster: values[i]
  }))
}
