'use strict'

export const positions = {}

export const eventStack = []
export const resizeStack = []

export const registerAnimationListener = (el) => eventStack.push(el)
export const registerResize = (el) => resizeStack.push(el)

function animateResize () {
  eventStack.forEach(v => v.on.animationFrame(v, v.state))
  window.requestAnimationFrame(animateResize)
}

// Start the animation
window.requestAnimationFrame(animateResize)

document.addEventListener('mousemove', (event) => {
  positions.mouseX = event.clientX
  positions.mouseY = event.clientY
})

window.addEventListener('resize', (event) => {
  positions.windowX = window.innerWidth
  positions.windowY = window.innerHeight
  resizeStack.forEach(v => v.on.resize(v, v.state))
})
