export default {
  COLOR: {
    light: '#ffffff',
    gray: '#6e6e72',
    dark: '#000000',
    blue: '#000000',
    primary: [
      '--dark',
      '--light'
    ],
    'primary-inversed': [
      '--light',
      '--dark'
    ]
  },
  GRADIENT: {
  },
  THEME: {
    document: {
      '@dark': {
        background: 'dark',
        color: 'light'
      },
      '@light': {
        background: 'light',
        color: 'dark'
      }
    },
    primary: {
    }
  },
  FONT: {
    'RS-Professional': [
      {
        url: 'https://based-files-production.based.dev/enD5U3P7x0/fia5bde6eb/a2d2cc96-6d17-4199-8922-dbff01ea0d5c-08873076-1679-4df8-a403-30478e4a23ec-ea1c9be0-00e8-4450-a2d0-be00190d7bee.woff',
        fontStyle: 'normal',
        fontWeight: '600',
        fontDisplay: 'block',
        isVariable: true
      }
    ],
    Figtree: [
      {
        url: 'https://based-files-production.based.dev/enD5U3P7x0/fi98a58619/37999dcc-71ab-4bbb-b72f-3d82b415a5d7-68517462-18eb-4b0a-93c9-7159ccf6e251-621a5448-8e66-49db-96cb-51fe9ec1acdd.woff',
        fontStyle: 'normal',
        fontWeight: '600',
        fontDisplay: 'block',
        isVariable: true
      }
    ]
  },
  FONT_FAMILY: {
    def: {
      type: 'serif',
      value: [
        'RS-Professional',
        'Figtree'
      ]
    }
  },
  TYPOGRAPHY: {
    base: 16,
    ratio: 1.25,
    subSequence: true,
    templates: {
    }
  },
  SPACING: {
    base: 16,
    ratio: 1.618,
    subSequence: true
  },
  TIMING: {
    base: 150,
    ratio: 1.333,
    subSequence: true,
    default: 'cubic-bezier(.29, .67, .51, .97)'
  },
  MEDIA: {
    tv: '(min-width: 2780px)',

    screenL: '(max-width: 1920px)',
    'screenL<': '(min-width: 1920px)',
    screenM: '(max-width: 1680px)',
    'screenM<': '(min-width: 1680px)',
    screenS: '(max-width: 1440px)',
    'screenS<': '(min-width: 1440px)',
    tabletL: '(max-width: 1366px)',
    'tabletL<': '(min-width: 1366px)',
    tabletM: '(max-width: 1280px)',
    'tabletM<': '(min-width: 1280px)',
    tabletS: '(max-width: 1024px)',
    'tabletS<': '(min-width: 1024px)',
    mobileL: '(max-width: 768px)',
    'mobileL<': '(min-width: 768px)',
    mobileM: '(max-width: 560px)',
    'mobileM<': '(min-width: 560px)',
    mobileS: '(max-width: 480px)',
    'mobileS<': '(min-width: 480px)',
    mobileXS: '(max-width: 375px)',
    'mobileXS<': '(min-width: 375px)',

    light: '(prefers-color-scheme: light)',
    dark: '(prefers-color-scheme: dark)',

    print: 'print',

    landscape: '(orientation: landscape)',
    portrait: '(orientation: portrait)',
    ultrawidescreen: '(min-aspect-ratio: 5/3)',
    widescreen: '(min-aspect-ratio: 5/4)',
    narrowscreen: '(max-aspect-ratio: 4/3) and (min-aspect-ratio: 1/1)',
    square: '(min-aspect-ratio: 4/5) and (max-aspect-ratio: 5/4)',
    narrowportrait: '(max-aspect-ratio: 3/4) and (min-aspect-ratio: 1/1)',
    wideportrait: '(max-aspect-ratio: 4/5)',
    ultrawideportrait: '(max-aspect-ratio: 3/5)'
  },
  RESET: {
    html: {
      overflow: 'hidden'
    },
    body: {
      overflow: 'hidden'
    },
    img: {
      userSelect: 'none'
    },
    '::-moz-selection': {
      color: 'white',
      background: 'black'
    },
    '::selection': {
      color: 'white',
      background: 'black'
    }
  },
  ANIMATION: {
    fadeIn: {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },
    fadeInUp: {
      // from: {
      //   transform: 'translate3d(0, 0, 1px) scale(.95)',
      //   opacity: 0
      // },
      // to: {
      //   transform: 'translate3d(0, 0, 1px)',
      //   opacity: 1
      // }
    }
  },
  ICONS: {
    logo: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.11861199,11.0779445 C10.867171,10.3593456 11.8798375,8.84811038 11.9015452,6.98193084 C11.9123991,6.02452485 11.9142081,5.01812348 11.9069721,3.96272673 C11.9067982,3.93113012 11.9245599,3.9024403 11.953285,3.88791931 C11.9820101,3.87339832 12.0171138,3.87536388 12.0448163,3.89304441 L23.3740897,11.2869914 C23.4532043,11.3393059 23.5005787,11.4282063 23.5,11.5232581 C23.4956531,12.3296857 23.4913116,13.1611554 23.48697,14.0176672 C23.4833521,14.6498891 23.3643212,15.2472698 23.1298776,15.8098093 C22.5336381,17.2412002 21.4916662,18.2022355 20.0039619,18.6929151 C19.6457841,18.8112299 19.293395,18.8707502 18.9467946,18.8714761 C16.93521,18.8780088 14.6790515,18.8798234 12.178319,18.87692 C11.9699246,18.87692 11.77962,18.8188514 11.6074052,18.7027142 C11.2781711,18.4813277 10.9464043,18.2624817 10.612105,18.0461761 C8.96448698,16.982795 7.38380118,15.9546179 5.87004759,14.9616449 C4.23834857,13.891731 2.55889251,12.8011301 0.83167941,11.6898423 C0.726035039,11.6216117 0.625455809,11.5548329 0.529941721,11.4895057 C0.500998057,11.4691817 0.49303855,11.4430508 0.506063198,11.4111131 C0.517337731,11.3814997 0.545435619,11.3619864 0.576613378,11.3621177 C2.93913989,11.355585 5.23473418,11.3552221 7.46339625,11.3610289 C8.10160402,11.3632065 8.6533426,11.268845 9.11861199,11.0779445 Z"/></svg>',
    left: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><polygon points="3.827 0 11.337 7.34 11.5 7.5 3.827 15 3.5 14.681 10.846 7.5 3.5 .319" transform="matrix(-1 0 0 1 15 0)"/></svg>',
    right: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><polygon points="3.827 0 11.337 7.34 11.5 7.5 3.827 15 3.5 14.681 10.846 7.5 3.5 .319"/></svg>',
    cross: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path d="M14.6804719,0.14 L15,0.452313627 L7.819,7.47 L7.819,7.476 L15,14.4945843 L14.6804721,14.8068984 L7.5,7.788 L0.319527851,14.8068984 L0,14.4945843 L7.18,7.476 L7.18,7.47 L0,0.452313627 L0.319528097,0.14 L7.5,7.158 L14.6804719,0.14 Z"/></svg>'
  },
  useReset: true,
  useVariable: true,
  useFontImport: true,
  useIconSprite: true,
  useSvgSprite: true,
  useDefaultConfig: true,
  useDocumentTheme: true,
  verbose: false,
  globalTheme: 'light'
}
