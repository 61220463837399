import { preloadImages } from '../snippets/utils'
import { ImageTrail } from '../snippets/imageTrail'

export const FloatingImg = {
  props: () => ({
    '--color-text': '#fff',
    '--color-bg': '#000',
    '--color-link': '#fff',
    '--color-link-hover': '#fff',
    '--img-width': '19vmax',
    '--img-ratio': '1.1',
    '--img-offset-x': '20px',
    '--img-offset-y': '20px',
    '--img-border-radius': '0',
    '--color-bg-content': 'rgba(255,255,255,0.05)',
    '--color-bg-demos': 'rgba(255,255,255,0.05)',

    pointerEvents: 'none',
    class: 'content',
    position: 'absolute',
    overflow: 'visible',
    zIndex: '0',
    inset: '0 0 0 0',

    current: window.location.pathname === '/',
    transition: 'B default opacity, B default visibility',
    '.current': {
      opacity: 1,
      visibility: 'visible'
    },
    '!current': {
      opacity: 0,
      visibility: 'hidden'
    }
  }),

  $collection: ({ state }) => {
    return state.main.data.map(v => ({
      props: {
        class: 'content__img',
        maxWidth: '24vmax',
        maxHeight: '24vmax',
        position: 'absolute',
        top: '0',
        left: '0',
        opacity: '0',
        overflow: 'hidden',
        willChange: 'transform, filter',
        style: {
          width: '-webkit-fill-available',
          height: '-webkit-fill-available'
        }
      },

      Img: {
        class: 'content__img-inner',
        src: `https://imgs-cache.symbo.ls/960/${v.poster}`,
        position: 'absolute',
        top: 'calc( -1 * var(--img-offset-y) / 2 )',
        left: 'calc( -1 * var(--img-offset-x) / 2 )',
        maxWidth: '100%',
        maxHeight: '100%'
      }
    }))
  },

  on: {
    render: ({ node, state }) => {
      preloadImages('.content__img-inner').then(() => {
        return new ImageTrail(node)
      })
    }
  }
}
