import { registerResize } from '../snippets/animationFrame'

const lineResize = el => {
  const { parent, node } = el
  const siblings = parent.node?.children
  const index = siblings && Array.from(siblings).indexOf(node) % 4
  const even = parseInt(index) % 2 === 0

  let prop = 'height'
  if (even) prop = 'width'
  const thisNode = el.node
  const lineNode = el.FrameLine.node
  lineNode.style.letterSpacing = '0px'

  window.requestAnimationFrame(() => {
    const diff = thisNode.getBoundingClientRect()[prop] - lineNode.getBoundingClientRect()[prop]
    const innerText = lineNode.innerText
    let lettersCount = innerText.replaceAll('\n', '').length

    if (el.key === 'Bottom') lettersCount = lettersCount - 18

    if (diff < -300) return
    const calc = `${diff / lettersCount}px`
    lineNode.style.letterSpacing = calc
  })
}

export const LayoutNav = {
  props: () => ({
    position: 'absolute',
    zIndex: 2,

    top: '--layout-outset',
    left: '--layout-outset',
    right: '--layout-outset',
    bottom: '--layout-outset',

    '--vertical-offset': '0.35em',
    '--type-tail': 'calc(-1 * var(--spacing-Z))',

    '!loaded': {
      '& nav': {
        opacity: '0',
        margin: '- - - 1.5em',
        transition: 'E default',
        transitionProperty: 'margin, opacity'
      }
    },
    onRender: (el, s) => {
      if (s.loaded) return
      const t = setTimeout(() => {
        el.setProps({
          '& nav': {
            margin: '0',
            opacity: '1'
          }
        })
        s.loaded = true
        clearTimeout(t)
      }, 250)
    }
  }),
  childExtend: {
    props: {
      flexAlign: 'center start',
      position: 'absolute',
      overflow: 'hidden',
      lineHeight: '1',
      zIndex: 2,
      fontSize: '2.965dvw',

      '@wideportrait': {
        fontSize: '4.44dvw'
      },
      '@ultrawideportrait': {
        fontSize: '6.4dvw'
      },
      ':nth-child(even)': {
        '> nav': {
          '> a:before': {
            top: '100%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 1px)'
          },
          '> a:after': {
            top: 'Z',
            bottom: 'Z',
            right: 'auto',
            left: 'W'
          }
        }
      }
    },
    on: {
      init: registerResize,
      resize: lineResize,
      render: el => {
        window.requestAnimationFrame(() => {
          lineResize(el)
        })
      }
    }
  },
  Top: {
    props: {
      top: '--type-tail',
      left: '-Z',
      right: '0'
    },
    FrameLine: {
      padding: '- - - 1.35em',
      content: {
        childProps: {
          '@ultrawideportrait': {
            ':nth-child(2)': {
              hide: true
            }
          }
        }
      }
    }
  },
  Right: {
    props: {
      right: '--type-tail',
      top: '--vertical-offset',
      bottom: '--vertical-offset',
      style: {
        writingMode: 'tb'
      }
    },
    FrameLine: {
      align: 'center center',
      transform: 'translate3d(0, 0, 0)',
      content: {
        childProps: {
          '@landscape': {
            ':nth-child(-n + 6)': {
              hide: true
            },
            ':nth-child(n + 10)': {
              ':before': {
                hide: true
              }
            },
            ':nth-child(n + 11)': {
              hide: true
            }
          },
          '@ultrawidescreen': {
            ':nth-child(n + 8)': {
              ':before': {
                hide: true
              }
            }
          },
          '@wideportrait': {
            ':nth-child(-n + 5)': {
              hide: true
            },
            ':nth-child(n + 8)': {
              ':before': {
                hide: true
              }
            },
            ':nth-child(n + 9)': {
              hide: true
            }
          }
        }
      }
    }
  },
  Bottom: {
    props: {
      transform: 'scale(-1)',
      top: 'initial',
      bottom: '--type-tail',
      left: '-X',
      right: '0'
    },
    FrameLine: {
      ':before': {
        content: '"•"',
        margin: '- -.2em 0 0'
      },
      childProps: {
        '@landscape': {
          ':nth-child(-n + 10)': {
            hide: true
          }
        },
        '@wideportrait': {
          order: 3,
          ':nth-child(-n + 10)': {
            hide: true
          },
          ':nth-child(12)': {
            hide: true
          },
          ':nth-child(13)': {
            hide: true
          },
          ':nth-child(14)': {
            hide: true
          },
          ':nth-child(15)': {
            order: 1
          },
          ':nth-child(11)': {
            order: 2
          }
        },
        '@ultrawideportrait': {
          ':nth-child(12)': {
            hide: false,
            show: true,
            display: 'block !important'
          },
          ':nth-child(15)': {
            hide: true
          },
          ':nth-child(16)': {
            hide: true
          },
          ':nth-child(17)': {
            hide: true
          }
        }
      }
    }
  },
  Left: {
    props: {
      left: '--type-tail',
      top: '--vertical-offset',
      bottom: '--vertical-offset',
      transform: 'scale(-1) translate3d(0,-.125em,0)',
      style: {
        writingMode: 'tb'
      }
    },
    FrameLine: {
      align: 'center center',
      content: {
        childProps: {
          '@landscape': {
            ':nth-child(-n + 15)': {
              hide: true
            },
            ':last-child:before': {
              hide: true
            }
          },
          '@portrait': {
            '& span': {
              hide: true
            },
            ':nth-child(-n + 11):not(:nth-child(2))': {
              hide: true
            },
            ':nth-child(15)': {
              hide: true
            },
            ':nth-child(12)': {
              hide: true
            }
          },
          Short: {
            '@wideportrait': {
              display: 'block !important',
              hide: false
            },
            '@landscape': {
              hide: true
            }
          },
          Long: {
            '@portrait': {
              hide: true
            }
          }
        }
      }
    }
  }
}
