export const about = {
  extend: 'Page',

  state: 'about',

  content: {
    props: {
      flexFlow: 'column',
      flexAlign: 'start space-between',
      gap: 'C1'
    },

    Img: {
      props: ({ state }) => ({
        position: 'relative',
        maxHeight: 'E1',
        src: state.poster,
        onClick: (ev, el) => el.setProps({ maxHeight: el.props.maxHeight === 'E1' ? 'H1' : 'E1' })
      })
    },

    Flex: {
      props: {
        gap: 'C2',

        '@wideportrait': {
          margin: 'C2 - -',
          flow: 'y'
        },
        '@tabletS': {
          flow: 'y'
        },

        childProps: {
          flexFlow: 'column',
          gap: 'A',
          flex: 1
        }
      },

      $stateCollection: ({ state }) => state.data,

      childExtend: {
        $collection: ({ state }) => Object.values(state.parse()).map(text => ({ Text: { text } }))
      }
    }
  }
}
