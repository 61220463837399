export const services = {
  extend: 'Page',

  state: 'services',

  props: {
    content: {
      overflow: 'visible'
    }
  },

  content: {
    props: {
      flexFlow: 'column',
      flexAlign: 'center center',
      textAlign: 'center',
      gap: '1.35ch',
      scrollPadding: '- 33%'
    },

    childExtend: 'ServiceItem',
    $stateCollection: ({ state }) => state.data
  }
}
