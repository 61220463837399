export const FrameLine = {
  extend: ['Flex'],
  tag: 'nav',
  props: {
    whiteSpace: 'nowrap',
    align: 'center',
    gap: '0',
    ':nth-child(even) > a': {
      ':before': {
        left: 'W',
        right: 'auto',
        bottom: '100%',
        top: 'Z',
        transition: 'A bottom ease',
        borderWidth: '0 0 3px 0'
      },
      ':hover:before': {
        bottom: 'Z'
      }
    },
    childProps: {
      padding: 'X .5em',
      gap: 'X'
    }
  },
  childExtend: {
    extend: 'NavLink',
    Span: {},
    Short: { if: ({ parent }) => parent.props.Short },
    Long: { if: ({ parent }) => parent.props.Long }
  },
  $propsCollection: [{
    href: '/',
    class: 'logo',
    position: 'absolute',
    left: '0',
    padding: '',
    margin: '- -Y1 - 0',
    style: {
      padding: '0 !important'
    },
    zIndex: 2,
    $href: null,
    ':after': {
      hide: true
    },
    ':before': {
      hide: true
    },
    Icon: {
      name: 'logo',
      fontSize: '1.5em'
    }
  },
  {
    text: 'Rosi Studios',
    href: '/',
    $href: {
      ':after': {
        opacity: 0
      }
    },
    '@portrait': {
      show: true,
      order: 10,
      ':before': {
        bottom: '100%',
        style: {
          top: 'auto !important',
          transform: 'translate3d(-50%, 50%, 1px) !important'
        }
      }
    }
  },
  {
    text: 'Archive',
    href: '/archive'
  },
  {
    text: 'Services',
    href: '/services'
  },
  {
    text: 'About',
    href: '/about'
  },
  {
    text: 'hello@rosistudios.com',
    href: 'mailto:hello@rosistudios.com'
  },
  {
    text: 'Working Hours'
  },
  {
    text: '10am – 6pm'
  },
  {
    text: 'Monday – Friday',
    '@widescreen': {
      hide: true
    },
    '@square': {
      show: true
    }
  },
  {
    text: 'Contact',
    href: '/about',
    '@ultrawidescreen': {
      hide: true
    }
  },
  {
    text: '29 Tabukashvili Street',
    target: '_blank',
    href: 'https://maps.app.goo.gl/AiQDdbaAZap9yPMo6'
  },
  {
    text: '0108',
    target: '_blank',
    href: 'https://maps.app.goo.gl/AiQDdbaAZap9yPMo6'
  },
  {
    text: 'Tbilisi',
    target: '_blank',
    href: 'https://maps.app.goo.gl/AiQDdbaAZap9yPMo6'
  },
  {
    text: 'Georgia',
    target: '_blank',
    href: 'https://maps.app.goo.gl/AiQDdbaAZap9yPMo6'
  },
  {
    text: '+995 555 025 252',
    href: 'tel:+995555025252'
  },
  {
    flexFlow: 'row',
    Span: {
      text: 'Follow on ',
      '@ultrawidescreen': {
        hide: true
      }
    },
    Short: {
      hide: true,
      text: 'IG'
    },
    Long: {
      text: 'Instagram'
    },
    target: '_blank',
    href: 'https://www.instagram.com/rosistudios',
    '@portrait': {
      order: 4,
      ':before': {
        hide: false,
        display: 'block !important',
        bottom: '100%',
        style: {
          top: 'auto !important',
          transform: 'translate3d(-50%, 50%, 1px) !important'
        }
      }
    }
  },
  {
    order: 3,
    flexFlow: 'row',
    Short: {
      text: '+995 555 025 252',
      href: 'tel:+995555025252'
    },
    Long: {
      text: 'Copyright © 2024'
    },
    Span: {
      order: 2,
      text: ' All rights reserved',
      '@widescreen': {
        hide: true
      }
    }
  }]
}
