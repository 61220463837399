import { isString } from 'smbls'

export const ArchiveLine = {
  extend: 'Flex',

  props: {
    flow: 'column',
    gap: '0',
    '@portrait': {
      fontSize: 'Z'
    }
  },

  NavLine: {
    props: {
      position: 'relative',
      theme: 'document',
      padding: '.3ch 0',
      flexFlow: 'row wrap',
      overflow: 'auto hidden',
      zIndex: 2
    },

    childExtend: {
      extend: 'NavLink',
      props: {
        whiteSpace: 'nowrap',
        padding: 'X 1ch',
        cursor: 'default'
      }
    },

    $collection: ({ state }) => {
      const title = isString(state.title) ? state.title.split(',').map(v => v.trim()) : state.title
      return title.concat({
        text: 'Credits',
        props: {
          ':hover': { textDecoration: 'underline' },
          onClick: (ev, el, s) => s.toggle('creditsActive')
        }
      })
    }
  },

  Credits: {
    childExtend: 'NavLink',
    props: {
      margin: '- -1ch Y2',
      flexFlow: 'row wrap',
      childProps: {
        whiteSpace: 'nowrap'
      },
      '.creditsActive': { hide: false },
      '!creditsActive': { hide: true }
    },
    $collection: ({ state }) => {
      return isString(state.credits) ? state.credits.split(',').map(v => v.trim()) : state.credits
    }
  },

  Gallery: {
    extend: 'Flex',

    props: {
      gap: 'Y2',
      height: 'E3',
      '@wideportrait': {
        height: '10dvh'
      },
      overflow: 'auto'
    },

    childExtend: {
      extend: 'Img',
      props: (el, s) => ({
        maxHeight: '100%',
        cursor: 'pointer',
        draggable: 'false',
        filter: 'blur(10px)',
        transition: 'G default',
        transitionProperty: 'opacity, visibility',
        transitionDelay: `${parseInt(el.parent.parent.parent.key) * 50 + parseInt(el.key) * 15}ms`,
        src: `https://imgs-cache.symbo.ls/15/${s.poster}`,
        onClick: (ev, el, s) => s.rootUpdate({
          current: [parseInt(el.parent.parent.parent.key), parseInt(el.key)],
          total: s.parent.data.length
        }),
        onLoad: (ev, el, s) => {
          if (el.props.loaded) return
          el.node.style.opacity = 1
          el.setProps({
            loaded: true,
            filter: 'none',
            src: `https://imgs-cache.symbo.ls/${window.innerWidth}x${window.innerHeight},fit/${s.poster}`
          })
        }
      })
    },

    $stateCollection: ({ state }) => state.data
  }
}
