export const LinkWithLine = {
  props: {

  }
}

export const NavLink = {
  extend: 'Link',
  props: ({ props }) => ({
    padding: 'X 1ch',
    lineHeight: '1',
    position: 'relative',
    $href: {
      ':after': {
        content: '\'\'',
        position: 'absolute',
        bottom: 'X',
        left: '0.8ch',
        right: '0.8ch',
        borderWidth: '0 0 0.065em',
        borderStyle: 'solid',
        borderColor: 'currentColor',
        opacity: props.href === window.location.pathname ? 1 : 0
      },
      ':hover:after': {
        opacity: 1
      }
    },
    ':not(:last-child):before': {
      content: '"•"',
      position: 'absolute',
      top: '50%',
      left: '100%',
      transform: 'translate3d(-50%, -50%, 1px)'
    }
  }),
  Icon: {
    if: ({ props }) => props.src
  }
}

export const NavLine = {
  props: {
    margin: '- - - -1ch'
  },

  childExtend: {
    extend: NavLink,
    props: {
      padding: 'X 1ch',
      cursor: 'default'
    }
  }
}
