
const isDomVisible = (value) => {
  const item = value.getBoundingClientRect()
  return (
    item.top >= 0 &&
        item.left >= 0 &&
        item.bottom <= (
          window.innerHeight ||
            document.documentElement.clientHeight) &&
        item.right <= (
          window.innerWidth ||
            document.documentElement.clientWidth)
  )
}

export const ImgPreview = {
  extend: ['Flex', 'Figure'],

  props: {
    flow: 'column',
    fontSize: '1em',
    position: 'fixed',
    zIndex: 4,
    margin: '0',
    inset: '0',
    justifyContent: 'space-between',

    '.current': {
      opacity: 1,
      visibility: 'visible'
    },
    '!current': {
      opacity: 0,
      visibility: 'hidden'
    }
  },

  Flex: {
    props: {
      class: 'draggable',
      align: 'center start',
      gap: 'A',
      position: 'absolute',
      inset: '0',
      overflow: 'auto',
      transition: 'B default inset',
      background: 'light .95',
      flex: 1,
      zIndex: 10,
      userSelect: 'none',

      ':before': {
        content: '""',
        minWidth: '33vw'
      },

      ':after': {
        content: '""',
        minWidth: '33vw'
      },

      style: {
        scrollSnapType: 'x mandatory'
      },

      onClick: (ev, el, s) => {
        s.toggle('current')
      }
    },

    childExtend: {
      extend: 'Img',

      props: ({ state, key }) => {
        return {
          maxWidth: '90%',
          maxHeight: '90%',
          draggable: 'false',
          cursor: 'default',
          src: `https://imgs-cache.symbo.ls/15/${state.poster}`,

          ':active': {
            cursor: 'grabbing'
          },

          style: {
            scrollSnapAlign: 'center'
          },

          '@mobileL<': {
            style: {
              width: '-webkit-fill-available',
              height: '-webkit-fill-available'
            }
          },

          onClick: (ev, el, s) => {
            const node = el.node
            if (!isDomVisible(node)) {
              ev.stopPropagation()
              ev.preventDefault()
              window.requestAnimationFrame(() => {
                node.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' })
                node.style.cursor = 'default'
                if (node.previousSibling) node.previousSibling.style.cursor = 'w-resize'
                if (node.nextSibling) node.nextSibling.style.cursor = 'e-resize'
              })
            }
          },

          scrollTo: (el, s, ctx, opts) => {
            const current = state.parent.current[1]
            const isActive = parseInt(el.key) === current
            const node = el.node

            if (isActive) {
              window.requestAnimationFrame(() => {
                const opts = { block: 'center', inline: 'center' }
                node.style.cursor = 'default'
                if (node.previousSibling) node.previousSibling.style.cursor = 'w-resize'
                if (node.nextSibling) node.nextSibling.style.cursor = 'e-resize'
                node.scrollIntoView(opts.smooth ? { behavior: 'smooth', ...opts } : opts)
              })
            }
          },

          onLoad: (ev, el, s, ctx, opts) => {
            if (el.props.loaded) return
            el.props.scrollTo(el, s, ctx, opts)
            el.setProps({
              loaded: true,
              src: `https://imgs-cache.symbo.ls/${window.innerWidth}x${window.innerHeight},fit/${s.poster}`
            })
          },
          onUpdate: (el, s, ctx, opts) => el.props.scrollTo(el, s, ctx, opts)
        }
      }
    },

    $stateCollection: ({ state }) => {
      return state.archive.data[state.current[0]]?.data
    }

  }
}
