export const ServiceItem = {
  extend: ['Link', 'Flex'],

  props: (el, s) => ({
    flow: 'y',
    align: 'center center',
    lineHeight: 1,
    fontSize: '10dvh',
    textAlign: 'center',
    position: 'relative',
    margin: '0.15ch -',
    cursor: 'pointer',

    activeService: s.parent.activeService,

    '.activeService': {
      margin: '0.5ch -',
      fontSize: 'C',
      '@portrait': {
        fontSize: 'A'
      }
    },

    '!activeService': {
      ':hover img': {
        opacity: 1
      }
    },

    '@wideportrait': {
      fontSize: '8.5dvw'
    },

    onMouseover: (ev, el, s) => {
      const { clientX, clientY } = ev
      el.data = { clientX, clientY }
    },

    onMousemove: (ev, el, s) => {
      const { clientX, clientY } = ev
      const { Img } = el
      const { node } = Img
      if (!node) return
      const x = el.data.clientX - clientX
      const y = el.data.clientY - clientY
      el.Img.node.style.transform = `translate3d(${x}px, ${y}px, 0px)`
    },

    onTouchstart: (ev, el, s) => {
      const { clientX, clientY } = ev
      el.data = { clientX, clientY }
    },

    onTouchmove: (ev, el, s) => {
      const { clientX, clientY } = ev
      const { Img } = el
      const { node } = Img
      if (!node) return
      const x = el.data.clientX - clientX
      const y = el.data.clientY - clientY
      el.Img.node.style.transform = `translate3d(${x}px, ${y}px, 0px)`
    },

    onClick: (ev, el, s) => {
      const isActive = s.title === s.parent.activeService
      s.parent.update({ activeService: isActive ? false : s.title })
    }
  }),

  Text: {
    props: (el, s) => ({
      isActive: s.parent.activeService === s.title,
      text: '{{ title }}',
      position: 'relative',
      ':after': {
        content: '\'\'',
        opacity: 0,
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        borderWidth: '0 0 .065em',
        borderStyle: 'solid',
        borderColor: 'currentColor'
      },
      ':hover': { ':after': { opacity: 1 } },
      '.isActive': { ':after': { opacity: 1 } }
    })
  },

  Img: {
    data: {},
    props: (el, s) => {
      const randomNegative = Math.random() - 0.5
      const x = randomNegative * 150
      const y = randomNegative * 50
      el.data.x = x
      el.data.y = y
      return {
        pointerEvents: 'none',
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        maxHeight: '350%',
        src: `https://img.gs/rcrmwzzbjj/840/${s.poster}`,
        zIndex: 10,
        opacity: 0
      }
    }
  },

  Overflow: {
    props: (el, s) => {
      const active = s.title === s.parent.activeService
      return {
        hide: !active
      }
    },
    P: {
      props: {
        fontSize: '2.965dvw',
        '@wideportrait': {
          fontSize: '4.44dvw'
        },
        '@ultrawideportrait': {
          fontSize: '6.4dvw'
        },
        margin: '.5ch 0 0'
      },
      $collection: ({ state }) => state.description.split('\n')
    }
  }
}
