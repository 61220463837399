import { main } from './main'
import { archive } from './archive'
import { services } from './services'
import { about } from './about'

export default {
  '/': main,
  '/archive': archive,
  '/services': services,
  '/about': about
}
