'use strict'

import { createSync } from 'smbls'

import * as data from '.'
import { FastClick } from 'fastclick'
import SYMBOLSRC from '../symbols.json'

(async () => await createSync({
  extend: 'Flex',

  props: {
    theme: 'document',
    flow: 'column',
    height: '100dvh',
    align: 'center space-between',

    '--layout-outset': '2.4vmax',
    '--content-outset': '3.85vmax'
  },

  LayoutNav: {},

  FloatingImg: {}
}, {
  key: SYMBOLSRC.key,
  isDevelopment: true,
  editor: {
    serviceRoute: ['state'],
    liveSync: true,
    remote: true
  }
}, data))()

FastClick.attach(document.body)
